import React from 'react';

const StopAudioButton = ({ color }) => {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 148 148'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M148 74C148 114.869 114.869 148 74 148C33.1309 148 0 114.869 0 74C0 33.1309 33.1309 0 74 0C114.869 0 148 33.1309 148 74Z'
        fill={color}
      />
      <circle cx='74' cy='74' r='66' fill={color} />
      <path
        d='M74 6C55.9653 6 38.6692 13.1643 25.9167 25.9167C13.1643 38.6692 6 55.9653 6 74C6 92.0347 13.1643 109.331 25.9167 122.083C38.6692 134.836 55.9653 142 74 142C92.0347 142 109.331 134.836 122.083 122.083C134.836 109.331 142 92.0347 142 74C142 55.9653 134.836 38.6692 122.083 25.9167C109.331 13.1643 92.0347 6 74 6ZM74 129.25C59.3468 129.25 45.2937 123.429 34.9324 113.068C24.571 102.706 18.75 88.6532 18.75 74C18.75 59.3468 24.571 45.2937 34.9324 34.9324C45.2937 24.571 59.3468 18.75 74 18.75C88.6532 18.75 102.706 24.571 113.068 34.9324C123.429 45.2937 129.25 59.3468 129.25 74C129.25 88.6532 123.429 102.706 113.068 113.068C102.706 123.429 88.6532 129.25 74 129.25ZM48.5 48.5H99.5V99.5H48.5V48.5Z'
        fill='white'
      />
    </svg>
  );
};

export default StopAudioButton;
