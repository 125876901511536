import React from 'react';

const PlayAudioButton = ({ color }) => {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 148 148'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M148 74C148 114.869 114.869 148 74 148C33.1309 148 0 114.869 0 74C0 33.1309 33.1309 0 74 0C114.869 0 148 33.1309 148 74Z'
        fill={color}
      />
      <path
        d='M27.4621 122.192C21.0629 116.012 15.9587 108.619 12.4473 100.444C8.93593 92.2701 7.08765 83.4784 7.01034 74.5822C6.93304 65.686 8.62825 56.8634 11.9971 48.6294C15.3659 40.3953 20.3408 32.9147 26.6317 26.6238C32.9225 20.333 40.4031 15.3581 48.6372 11.9893C56.8713 8.62044 65.6938 6.92522 74.59 7.00253C83.4862 7.07984 92.278 8.92811 100.452 12.4395C108.626 15.9509 116.02 21.0551 122.2 27.4543C134.405 40.0907 141.158 57.015 141.005 74.5822C140.853 92.1494 133.806 108.954 121.384 121.376C108.962 133.798 92.1572 140.845 74.59 140.997C57.0228 141.15 40.0985 134.397 27.4621 122.192ZM112.753 112.745C122.811 102.688 128.461 89.0468 128.461 74.8233C128.461 60.5998 122.811 46.9588 112.753 36.9013C102.696 26.8438 89.0546 21.1935 74.8311 21.1935C60.6076 21.1935 46.9667 26.8438 36.9091 36.9013C26.8516 46.9588 21.2013 60.5998 21.2013 74.8233C21.2013 89.0468 26.8516 102.688 36.9091 112.745C46.9667 122.803 60.6076 128.453 74.8311 128.453C89.0546 128.453 102.696 122.803 112.753 112.745ZM54.7311 48.0233L108.331 74.8233L54.7311 101.623V48.0233Z'
        fill='white'
      />
    </svg>
  );
};

export default PlayAudioButton;
