export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const USER_LOADED = 'USER_LOADED';
export const SET_USERS = 'SET_USERS';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_ERROR = 'SET_ERROR';
export const GET_PARTNERS_DATA = 'SET_PARTNERS_DATA';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SET_LOADING = 'SET_LOADING';
export const LIST_POSTS = 'LIST_POSTS';
export const LIST_POSTS_FAIL = 'LIST_POSTS_FAIL';
export const LIST_SINGLE_POST = 'LIST_SINGLE_POST';
export const LIST_SINGLE_POST_FAIL = 'LIST_SINGLE_POST';
export const PROGRESS_UPLOAD = 'PROGRESS_UPLOAD';

export const LIST_AUTHORS = 'LIST_AUTHORS';
export const LIST_PERSONS_FAIL = 'LIST_AUTHORS_FAIL';
export const SET_CATEGORY = 'SET_CATEGORY';
export const COUNT_VISITORS = 'COUNT_VISITORS';
export const GET_VIDEOS_DATA = 'GET_VIDEOS_DATA';

export const GET_HEADER_CONFIG = 'GET_HEADER_CONFIG';
